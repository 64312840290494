<template>
  <OCarouselOffer
    class="o-category-carousel-offer"
    :title="$t('pages.main.high-rating')"
    title-level="2"
    :break-points="breakpoints"
    with-background
  >
    <ACategory
      v-for="item in categories"
      :key="item.id"
      class="o-category-carousel-offer__a-category"
      :item="item"
      with-pixel-ratio
      with-webp-img
    />
  </OCarouselOffer>
</template>

<script lang="ts" setup>
import { useLocalization } from '#imports'

import type { Breakpoints } from '@/composables/device'
import type { Category } from '@/components/atoms/Category/types'

import OCarouselOffer from '@/components/organisms/CarouselOffer/OCarouselOffer.vue'
import ACategory from '@/components/atoms/Category/ACategory.vue'

const { t } = useLocalization()

const breakpoints: Record<Breakpoints, number> = {
  mobile: 7,
  laptop: 4,
  desktop: 5
}

const { data: categories } = await useHydrationData('home-rating-categories', async () => {
  function getCategories (): Category[] {
    return [
      {
        id: 0,
        title: t('molecules.header.wine-red'),
        path: 'wine/red/?sorting=reviewDESCnew',
        src: '/assets/images/categories/1_208x208.png',
        mobileSrc: '/assets/images/categories/1_136x200.png'
      },
      {
        id: 1,
        title: t('molecules.header.wine-white'),
        path: 'wine/white/?sorting=reviewDESCnew',
        src: '/assets/images/categories/2_208x208.png',
        mobileSrc: '/assets/images/categories/2_136x200.png'
      },
      {
        id: 2,
        title: t('molecules.header.champagne'),
        path: 'champagnes-and-sparkling/all/?sorting=reviewDESCnew',
        src: '/assets/images/categories/3_208x208.png',
        mobileSrc: '/assets/images/categories/3_136x200.png'
      },
      {
        id: 3,
        title: t('molecules.header.whiskey'),
        path: 'whisky/all/?sorting=reviewDESCnew',
        src: '/assets/images/categories/4_208x208.png',
        mobileSrc: '/assets/images/categories/4_136x200.png'
      },
      {
        id: 4,
        title: t('molecules.header.cognac'),
        path: 'cognac/all/?sorting=reviewDESCnew',
        src: '/assets/images/categories/5_208x208.png',
        mobileSrc: '/assets/images/categories/5_136x200.png'
      },
      {
        id: 5,
        title: t('molecules.header.beer'),
        path: 'beer/all/?sorting=reviewDESCnew',
        src: '/assets/images/categories/6_208x208.png',
        mobileSrc: '/assets/images/categories/6_136x200.png'
      }
    ]
  }

  return await getCategories()
}, { default: [], lazy: true })
</script>

<style lang="postcss">
.o-category-carousel-offer {
  margin-top: var(--spacer-5xl);

  @media (--screen-xs) {
    margin-top: var(--spacer-4xl);

    & .carousel {
      --carousel-item-width: 8.5rem;
    }
  }

  @media (--screen-lg) {
    &__a-category {
      &.category {
        --category-img-width: 152px;
        --category-img-height: 152px;
        --category-gap: var(--spacer-xs);
        --category-title-position: static;
        --category-border-raduis: 0;
        --category-title-align: center;
        --category-overflow: visible;

        mask-image: none;

        & > .image {
          mix-blend-mode: darken;

          /* background: var(--color-neutral-200); */
        }

        @mixin text-base-bold;
      }
    }

    & .carousel {
      margin-top: calc(-1 * var(--spacer-lg));

      & > div:first-child {
        padding-top: var(--spacer-lg);
      }
    }
  }
}
</style>
